<template>
  <div class="registered">
    <div class="content">
      <van-form @submit="onSubmit">
        <div class="label">请输入手机号</div>
        <van-field
          v-model="setInfo.phone"
          name="phone"
          placeholder="请输入手机号"
          type="tel"
        />
        <div class="label">请输入密码</div>
        <van-field
          v-model="setInfo.pwd"
          type="password"
          name="pwd"
          placeholder="请输入密码"
        />
        <div class="label">请确认密码</div>
        <van-field
          v-model="setInfo.pwd1"
          type="password"
          name="pwd1"
          placeholder="确认密码"
        />
        <div style="margin: 16px;margin-top:50px;">
          <van-button
            round
            block
            type="info"
            color="#2163E9"
            native-type="submit"
            >注册</van-button
          >
        </div>
      </van-form>
       <div class="footer">
        <img src="@/assets/xygLogo.svg" alt="">
        <div style="color:#2163E9 ">小亿国眼健康商城</div>
      </div>
    </div>
  </div>
</template>

<script>
import { setLocal } from '@/utils/local.js'
export default {
  data () {
    return {
        setInfo:{
            phone: '',
            pwd: '',
            pwd1:''
        }
    }
  },
  created () {
  },
  mounted () {
      this.setInfo.phone = ''
      this.setInfo.pwd = ''
      this.setInfo.pwd1 = ''
  },
  methods : {
      onSubmit (value) {
           if (value.phone.length != 11) {
              this.$toast.fail('手机号长度不足或超出长度！')
              return
          }
          if(value.pwd === value.pwd1) {
              this.$toast.success('注册成功！')
              setLocal('account',JSON.stringify(this.setInfo))
              setTimeout(() => {
                  this.$router.push({path:'/login'})
              }, 500);
          }else {
              console.log(value);
              this.$toast.fail('密码不一致')
              return
          }
          
         
      }
  }
}
</script>

<style lang="less" scoped>
    .registered {
        padding: 0 20px;
        padding-top: 100px;
        .content {
          position: relative;
            .footer {
              font-size:14px;
              text-align:center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -200px;
              padding-bottom: 50px;
            }
        }
        .label {
            padding:15px 5px;
            font-size: 12px;
            color: #333333;
        }
        ::v-deep .van-field {
            border: 1px solid #ccc;
            padding: 10px 5px;
        }
        
    }
</style>
